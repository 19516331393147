import React from 'react';
import { Header } from "../components/Header";
import { Body } from "../components/Body";

export const Home = () => {
    return (
        <>
            <Header />
            <Body />
        </>
    )
}