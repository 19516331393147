import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import "../style/style.css";

export const InputField = ({
  setInputText,
  inputText,
  setSourceLanguage,
  sourceLanguage,
  loader,
}) => {
  const [error, setError] = useState("");

  const handleInput = (event) => {
    const text = event.target.value;
    const words = text.trim().split(/\s+/);

    if (words.length <= 200) {
      setInputText(text);
      setError("");
    } else {
      setError("Only the first 200 words can be translated at once");
      const truncatedText = words.slice(0, 200).join(" ");
      setInputText(truncatedText);
    }
  };

  const clearInput = () => {
    setInputText("");
    setError("");
  };

  const handleLanguageChange = (event) => {
    setSourceLanguage(event.target.value);
  };

  return (
    <div className="col-md-6 col-xl-6 margin-btwn-text-fields">
      <div className="margin-btwn-text-fields">
        <FormControl fullWidth>
          <InputLabel id="source-language-label">Source Language</InputLabel>
          <Select
            labelId="source-language-label"
            id="source-language-select"
            value={sourceLanguage}
            label="Source Language"
            onChange={handleLanguageChange}
            disabled={loader}
            className="width-100"
          >
            <MenuItem value={"Malayalam"}>Malayalam</MenuItem>
            <MenuItem value={"English"}>English</MenuItem>
            <MenuItem value={"Hindi"}>Hindi</MenuItem>
            <MenuItem value={"Tamil"}>Tamil</MenuItem>
          </Select>
        </FormControl>
      </div>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "90%" },
        }}
        noValidate
        autoComplete="off"
        className="input-box font-size"
      >
        <div className="input-wrapper">
          <TextField
            id="outlined-multiline-static"
            multiline
            rows={6}
            value={inputText}
            placeholder="Enter your text"
            variant="standard"
            InputProps={{
              disableUnderline: true,
              classes: { root: "text-field" },
              style: {
                fontSize: "2rem",
              },
            }}
            className="font-size"
            onChange={handleInput}
            onKeyDown={(e) => {
              if (e.key === "Enter") e.preventDefault(); // Prevent form submission on Enter
            }}
            disabled={loader}
          />
          {inputText && !loader && (
            <Tooltip title="Clear">
              <IconButton
                className="clear-button"
                onClick={clearInput}
                aria-label="clear"
                style={{
                  visibility: inputText ? "visible" : "hidden",
                  position: "absolute",
                  top: "5%",
                  right: "5px",
                  padding: 0,
                }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
        <div className="word-count-container">
          <div className="word-count">
            {inputText.trim().split(/\s+/).length} / 200 words
          </div>
        </div>
        {error && <div className="error-message">{error}</div>}
      </Box>
    </div>
  );
};
