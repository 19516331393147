import React from 'react'

import govtOfKeralaLogo from "../static/images/eleicfoss.png"
import icfossLogo from "../static/images/icwhite.png"

import "../style/style.css";

export const Header = () => {
  return (
    <div className='header'>
      <div id="navbar" className='display-flex-new height-100'>
        <div className='logo-container-govt height-100 display-flex'>
          <img src={govtOfKeralaLogo} width="100%" height="auto" alt='' />
        </div>
        <div className=' font-color height-100 display-flex'>
          <h1>Malayalam English Bidirectional Translator</h1>
        </div>
        <div className='height-100 logo-container-icfoss display-flex'>
          <img src={icfossLogo} width="100%" height="auto" alt='' />
        </div>
      </div>
    </div>
  )
}
