import { BASE_URL, END_POINT,END_POINT1} from './loader';
import axios from 'axios';

export async function query(data) {
	try {
		const URL = BASE_URL + END_POINT;
		const response = await axios.post(
			URL,
			data,
			{
				headers: {
					'Content-Type': 'application/json',
				}
			}
		);
		return response
	} catch (error) {
		throw error
	}
}

export async function query1(data) {
	try {
		const URL = BASE_URL + END_POINT1;
		const response = await axios.post(
			URL,
			data,
			{
				headers: {
					'Content-Type': 'application/json',
				}
			}
		);
		return response
	} catch (error) {
		throw error
	}
}



