import React, { useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { InputField } from "./InputField";
import { OutputField } from "./OutputField";
import { Loader } from './Loader';
import { Footer } from "./Footer";
import "../style/style.css";
import { query, query1} from "../config/api"; // Including all query functions

export const Body = () => {
  const [outputText, setOutputText] = useState("");
  const [loader, setLoader] = useState(false);
  const [inputText, setInputText] = useState('');
  const [sourceLanguage, setSourceLanguage] = useState('Malayalam'); // Initial source language
  const [targetLanguage, setTargetLanguage] = useState('English'); // Initial target language

  const handleLanguageSwap = () => {
    setSourceLanguage(prevSourceLanguage => {
      const newSourceLanguage = targetLanguage;
      setTargetLanguage(prevSourceLanguage);
      return newSourceLanguage;
    });
  };

  const handleTranslation = async () => {
    try {
      setLoader(true);
      const data = {
        inputs: inputText,
      };

      let response;

      if (sourceLanguage === 'Malayalam' && targetLanguage === 'English') {
        response = await query(data); // Malayalam to English
      } else if (sourceLanguage === 'English' && targetLanguage === 'Malayalam') {
        response = await query1(data); // English to Malayalam
      } else if (sourceLanguage === 'Hindi' && targetLanguage === 'Malayalam') {
        response = await query1(data); // Hindi to Malayalam
      } else if (sourceLanguage === 'Tamil' && targetLanguage === 'Malayalam') {
        response = await query1(data); // Tamil to Malayalam
      } else {
        setOutputText('Translation not supported for selected languages.');
        return;
      }

      if (response.data && response.data.hasOwnProperty("result")) {
        setLoader(false);
        setOutputText(response.data["result"]);
      } else {
        setOutputText('Translation failed. Please try again.');
      }
    } catch (error) {
      console.error('Error during translation:', error);
      setOutputText('An error occurred during translation. Please try again.');
    } finally {
      setLoader(false);
    }
  };

  return (
    <Box className='container' style={{ marginTop: '50px' }}>
      <Box 
        className='language-container' 
        display="flex" 
        alignItems="center"
        justifyContent="center"
        position="relative"
      >
        <InputField
          setInputText={setInputText}
          inputText={inputText}
          setSourceLanguage={setSourceLanguage}
          sourceLanguage={sourceLanguage}
          loader={loader}
        />
        <Tooltip title="Swap languages">
          <IconButton 
            onClick={handleLanguageSwap} 
            className='swap-button'
            style={{
              top:'1px', 
              margin: '0 10px', 
              width: 40, 
              height: 40
            }}
          >
            <SwapHorizIcon style={{ width: '100%', height: '100%' }} />
          </IconButton>
        </Tooltip>
        <OutputField
          outputText={outputText}
          targetLanguage={targetLanguage}
          setTargetLanguage={setTargetLanguage}
        />
      </Box>

      <Box display="flex" justifyContent="center" mt={6}>
        <Tooltip title="Translate the text">
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleTranslation} 
            disabled={loader || !inputText}
          >
            Translate
          </Button>
        </Tooltip>
      </Box>

      {loader && <Box display="flex" justifyContent="center" mt={2}><Loader /></Box>}
      <Footer />
    </Box>
  );
};
