import dotenv from "dotenv";

dotenv.config()

export const BASE_URL =  window.location.origin;

export const END_POINT = '/api/generate';

export const END_POINT1 = '/api/translate';

