import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import "../style/style.css";

export const OutputField = ({
  outputText,
  targetLanguage,
  setTargetLanguage,
}) => {
  const handleCopyClick = () => {
    navigator.clipboard.writeText(outputText); // Copy text to clipboard
  };

  const handleLanguageChange = (event) => {
    setTargetLanguage(event.target.value);
  };

  return (
    <div className="col-md-6 col-xl-6 margin-btwn-text-fields">
      <div className="margin-btwn-text-fields">
        <FormControl fullWidth>
          <InputLabel id="target-language-label">Target Language</InputLabel>
          <Select
            labelId="target-language-label"
            id="target-language-select"
            value={targetLanguage}
            label="Target Language"
            onChange={handleLanguageChange}
            className="width-100"
          >
            <MenuItem value={"Malayalam"}>Malayalam</MenuItem>
            <MenuItem value={"English"}>English</MenuItem>
            <MenuItem value={"Hindi"}>Hindi</MenuItem>
            <MenuItem value={"Tamil"}>Tamil</MenuItem>
          </Select>
        </FormControl>
      </div>
      <Box
        component="div"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          backgroundColor: "#fafafa",
          borderRadius: 1,
          "& .MuiTextField-root": { m: 1, width: "100%" },
        }}
        className="input-box"
      >
        <TextField
          id="outlined-multiline-english"
          multiline
          rows={6}
          value={outputText}
          placeholder="Translated text will appear here..."
          disabled
          variant="standard"
          InputProps={{
            disableUnderline: true,
            style: {
              fontSize: "2rem",
              color: "#000000",
            },
          }}
        />
        {outputText && (
          <Tooltip title="Copy translated text">
            <IconButton onClick={handleCopyClick} aria-label="Copy translated text">
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </div>
  );
};
