import "bootstrap/dist/css/bootstrap.min.css";
import React from 'react';
import { Home } from "./pages/Home";

function App() {
  return (
    <div>
      <Home />
    </div>
  );
}

export default App;
